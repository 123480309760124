<template>
  <div>
    <b-button
      type="button"
      variant="primary"
      class="mb-2"
      :to="{ name: 'platforms-create' }"
    >
      <span class="text-nowrap">Creer une plateforme</span>
    </b-button>
    <b-card no-body>
      <platforms-list-filters
        :competition-filter.sync="competitionFilter"
        :trashed-filter.sync="trashedFilter"
        :competition-options="competitionOptions"
        :trashed-options="trashedOptions"
      />
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="fetchPlatforms(true)"
            />
            <label>entrées</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form @submit.prevent="fetchPlatforms(true)">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
                <b-button
                  type="submit"
                  variant="primary"
                >
                  <span class="text-nowrap">Chercher</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="platforms"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun résultat trouvé"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="loading"
        @sort-changed="sortPlatforms($event)"
      >
        <!-- busy -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <!-- Column: platform -->
        <template #cell(name)="data">
          <b-media
            vertical-align="center"
            :class="{'cursor-not-allowed':!!data.item.deleted_at}"
          >
            <template #aside>
              <b-avatar
                :disabled="!!data.item.deleted_at"
                :class="{'cursor-not-allowed':!!data.item.deleted_at}"
                size="32"
                :src="data.item.favicon"
                :text="avatarText(data.item.name)"
                :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
              />
            </template>
            <b-link
              :disabled="!!data.item.deleted_at"
              :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
              class="font-weight-bold d-block text-nowrap"
              :style="{cursor: data.item.deleted_at ? 'not-allowed': ''}"
            >
              {{ data.item.name }}
            </b-link>
            <a
              :href="`https://${data.item.subdomain}.ccup.io`"
              target="_blank"
              class="font-weight-bold d-block text-nowrap text-muted"
            >{{ data.item.subdomain }}.ccup.io</a>
          </b-media>
        </template>

        <!-- Column: competitions -->
        <template #cell(competitions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="DribbbleIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">
              <b-badge
                v-for="competition in data.value"
                :key="competition.uuid"
                class="mr-1"
              >
                {{ competition.name }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(slot)="data">
          <span>{{ data.item.users_count }}/{{ data.value }}</span>
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          <span>{{ moment(data.value).format("LLL") }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <template v-if="!data.item.deleted_at">
              <feather-icon
                :id="`platform-row-${data.item.uuid}-show-icon`"
                icon="EyeIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'platforms-view', params: { id: data.item.uuid }})"
              />
              <b-tooltip
                title="Détails"
                class="cursor-pointer"
                :target="`platform-row-${data.item.uuid}-show-icon`"
              />

              <feather-icon
                :id="`platform-row-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mx-1"
                size="16"
                @click="$router.push({ name: 'platforms-edit', params: { id: data.item.uuid }})"
              />
              <b-tooltip
                title="Éditer"
                class="cursor-pointer"
                :target="`platform-row-${data.item.uuid}-edit-icon`"
              />

              <feather-icon
                :id="`platform-row-${data.item.uuid}-delete-icon`"
                v-b-modal="`modal-delete-platform-${data.item.uuid}`"
                icon="TrashIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Supprimer"
                class="cursor-pointer"
                :target="`platform-row-${data.item.uuid}-delete-icon`"
              />
              <b-modal
                :id="`modal-delete-platform-${data.item.uuid}`"
                :ref="`modal-delete-platform-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Supprimer"
                modal-class="modal-danger"
                centered
                title="Suppression de la plateforme"
              >
                <b-card-text>
                  Souhaitez-vous vraiment supprimer cette plateforme ?<br>
                  Ces éléments seront supprimés :<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Équipe<br>
                  -Prix<br>
                  -Messages<br>
                  -Chatbot (et ses messages)<br>
                  -Mails autorisés<br>
                  -Invitations<br>
                  -Sponsors<br>
                  -Notifications<br>
                  ✔Les utilisateurs seront encore accessibles
                  <p class="text-danger font-weight-bold">
                    (Cette action est réversible)
                  </p>
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-delete-platform-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="danger"
                    :disabled="actionLoading"
                    @click="deletePlatform(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="actionLoading"
                      small
                    />
                    supprimer
                  </b-button>
                </template>
              </b-modal>
            </template>
            <template v-if="data.item.deleted_at">
              <feather-icon
                :id="`platform-row-${data.item.uuid}-restore-icon`"
                v-b-modal="`modal-restore-platform-${data.item.uuid}`"
                icon="RotateCcwIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
              <b-tooltip
                title="Restaurer"
                class="cursor-pointer"
                :target="`platform-row-${data.item.uuid}-restore-icon`"
              />
              <b-modal
                :id="`modal-restore-platform-${data.item.uuid}`"
                :ref="`modal-restore-platform-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Supprimer"
                modal-class="modal-danger"
                centered
                title="Restauration de la plateforme"
              >
                <b-card-text>
                  Souhaitez-vous vraiment restaurer cette plateforme ?<br>
                  Ces éléments seront restaurés :<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Équipe<br>
                  -Prix<br>
                  -Messages<br>
                  -Chatbot (et ses messages)<br>
                  -Mails autorisés<br>
                  -Invitations<br>
                  -Sponsors<br>
                  -Notifications<br>
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-restore-platform-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="success"
                    :disabled="actionLoading"
                    @click="restorePlatform(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="actionLoading"
                      small
                    />
                    restaurer
                  </b-button>
                </template>
              </b-modal>
              <!-- force delete -->
              <feather-icon
                :id="`platform-row-${data.item.uuid}-force-delete-icon`"
                v-b-modal="`modal-force-delete-platform-${data.item.uuid}`"
                icon="TrashIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Supprimer définitivement"
                class="cursor-pointer"
                :target="`platform-row-${data.item.uuid}-force-delete-icon`"
              />
              <b-modal
                :id="`modal-force-delete-platform-${data.item.uuid}`"
                :ref="`modal-force-delete-platform-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Supprimer"
                modal-class="modal-danger"
                centered
                title="Suppression définitive de la plateforme"
              >
                <b-card-text>
                  Souhaitez-vous vraiment supprimer définitivement cette plateforme ?<br>
                  Ces éléments seront supprimés définitivement :<br>
                  -Utilisateurs<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Équipe<br>
                  -Prix<br>
                  -Messages<br>
                  -Chatbot (et ses messages)<br>
                  -Mails autorisés<br>
                  -Invitations<br>
                  -Sponsors<br>
                  -Notifications<br>
                  <p class="text-danger font-weight-bold">
                    (CETTE ACTION EST IRRÉVERSIBLE)
                  </p>
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-force-delete-platform-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="danger"
                    :disabled="actionLoading"
                    @click="forceDeletePlatform(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="actionLoading"
                      small
                    />
                    supprimer
                  </b-button>
                </template>
              </b-modal>
            </template>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.total }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPlatforms"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BSpinner,
  BForm, BModal, BCardText, BTooltip, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PlatformsListFilters from './PlatformsListFilter.vue'

export default {
  name: 'Platforms',
  components: {
    PlatformsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    BModal,
    BCardText,
    BTooltip,
    BBadge,
  },
  setup() {
    const resolveUserRoleVariant = role => {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    }
    const resolveUserRoleIcon = role => {
      if (role === 'subscriber') return 'UserIcon'
      if (role === 'author') return 'SettingsIcon'
      if (role === 'maintainer') return 'DatabaseIcon'
      if (role === 'editor') return 'Edit2Icon'
      if (role === 'admin') return 'ServerIcon'
      return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
      if (status === 'pending') return 'warning'
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    }
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
  data() {
    return {
      loading: true,
      actionLoading: false,
      competitionFilter: null,
      competitionOptions: [],
      trashedFilter: false,
      trashedOptions: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      tableColumns: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
        },
        {
          key: 'competitions',
          label: 'Compétition',
          sortable: false,
        },
        {
          key: 'slot',
          label: 'Utilisateurs',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        { key: 'actions' },
      ],
      sortBy: 'name',
      isSortDirDesc: false,
      platforms: [],
      currentPage: 1,
      totalPlatforms: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  watch: {
    trashedFilter: {
      handler() {
        this.fetchPlatforms(true)
      },
    },
    competitionFilter: {
      handler() {
        this.fetchPlatforms(true)
      },
    },
  },
  mounted() {
    this.fetchPlatforms(true)
    this.fetchCompetitions()
  },
  methods: {
    async fetchPlatforms(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get('platforms', {
          params: {
            onlyTrashed: this.trashedFilter,
            competition_id: this.competitionFilter,
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
          },
        })
        this.platforms = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalPlatforms = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async fetchCompetitions() {
      try {
        const { data } = await axiosIns.get('/competitions/minimal')
        data.forEach(competition => {
          this.competitionOptions.push({ label: competition.name, value: competition.uuid })
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deletePlatform(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.delete(`platforms/${uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-delete-platform-${uuid}`)
        this.fetchPlatforms()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    async restorePlatform(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.post(`platforms/${uuid}/restore`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme a été restaurée',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-restore-platform-${uuid}`)
        this.fetchPlatforms()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    async forceDeletePlatform(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.delete(`platforms/${uuid}/forceDelete`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme a été définitivement supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-force-delete-platform-${uuid}`)
        this.fetchPlatforms()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    sortPlatforms(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchPlatforms()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchPlatforms()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
